// material
import { styled } from '@mui/material/styles';
import { Container, Button, Card, Typography } from '@mui/material';
// hooks
import { Auth } from 'aws-amplify';
import { enqueueSnackbar } from 'notistack';
import useAuth from '../../hooks/useAuth';
// components;
import LoginForm from './LoginForm';
import SetNewPasswordForm from './SetNewPasswordForm';
import { NEW_PASSWORD_REQUIRED_CHALLENGE } from '../../constants';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
	maxWidth: 480,
	margin: 'auto',
	display: 'flex',
	minHeight: '100vh',
	flexDirection: 'column',
	justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function Login() {
	const { federatedId, code } = useAuth();

	const showEmailLogin = window.location.search.includes('email=true');

	const handleSSOClick = async () => {
		try {
			await Auth.federatedSignIn({ provider: federatedId });
		} catch (error) {
			console.log('error', error);
			enqueueSnackbar('Error logging in via SSO', { variant: 'error' });
		}
	};

	return (
		<Container sx={{ mt: 0, pb: 0 }} maxWidth="sm">
			<ContentStyle>
				<img
					alt="Responsum Logo"
					style={{ width: 250, margin: 'auto', marginBottom: 50, marginTop: -50 }}
					src="/images/logos/logo-full-dark-background.png"
				/>
				{code === NEW_PASSWORD_REQUIRED_CHALLENGE ? (
					<SetNewPasswordForm />
				) : federatedId && !showEmailLogin ? (
					<Card sx={{ p: 3 }}>
						<Typography align="center" variant="h4" gutterBottom>
							Sign In to Responsum
						</Typography>
						<Typography
							color="text.secondary"
							align="center"
							variant="body2"
							gutterBottom
							sx={{ mb: 2 }}
						>
							Click to Sign In with Single Sign-On
						</Typography>
						<Button
							sx={{ mt: 2 }}
							fullWidth
							size="large"
							variant="contained"
							onClick={handleSSOClick}
						>
							Sign In
						</Button>
					</Card>
				) : (
					<LoginForm />
				)}
			</ContentStyle>
		</Container>
	);
}
